import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const PalettenWerk = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "PalettenWerk",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #00a77e, #007a5c, #00664d, #015842, #015842 )",
        icons: "#f5821f",
        navClass: "bogucianka",
        ogImage: require("../../assets/img/portfolio/palettenwerk_top_back.jpg"),
      }}
    >
      <PortfolioHeader name="palettenwerk" height="176" />
      <section className="container-fluid palettenwerk_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>PalettenWerk</h1>
              <ul>
                <li>Refresh logo na 30lecie</li>
                <li>Strona internetowa</li>
                <li>Stopki mailowe</li>
                <li>Banery statyczne</li>
                <li>Banery dynamiczne</li>
                <li>Kampania leadowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Firma PalettenWerk zgłosiła się do nas z potrzebą zrealizowania
                szeregu projektów oraz kampanii reklamowej ze względu na
                nadchodzące 30 urodziny firmy.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid palettenwerk_section_3">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <div className="inner">
              <h3>Refresh logo na 30-lecie firmy</h3>
              <p>
                W ramach naszej współpracy odświezylismy logo tak zeby pokreślić
                30 lat działalności firmy.
              </p>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/paletten_logo.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid palettenwerk_section_4">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/paletten_fullpage.jpg")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid palettenwerk_section_5">
        <div className="row row_1">
          <div className="col-md-4 offset-md-1">
            <div className="inner">
              <h3>Strona internetowa</h3>
              <p>
                Wspólnie z Klientem wypracowaliśmy nową, szybką i nowoczesną
                stronę internetową, zakodowaną jako JamStack poprzez połączenie
                najnowszych technologii programistycznych tj. GatsbyJS
                (front-end) wraz z powszechnie znanym i lubianym panelem
                WordPress do zarządzania stroną (back-end). Nowa strona ma za
                zadanie pokazać firmę PalettenWerk jako lidera krajowego rynku
                palet, a także doświadczenie jakie kryje się za marką i długą
                drogę, którą przez 30 lat działalności przeszła ta rodzinna
                firma by znaleźć się w obecnym miejscu.
              </p>
            </div>
          </div>
          <div className="col-md-10 offset-md-2">
            <img
              src={require("../../assets/img/portfolio/palettenwerk_www.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid palettenwerk_section_6">
        <div className="row">
          <div className="col-md-10">
            <img
              src={require("../../assets/img/portfolio/palettenwerk_img_2.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid palettenwerk_section_7">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <img
              src={require("../../assets/img/portfolio/palettenwerk_img_3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-4 offset-md-1">
            <div className="inner">
              <h3>Stopki mailowe</h3>
              <p>
                Aby zachować spójność kreacji używanych przez firmę na
                wszystkich frontach stworzyliśmy także unikatowe stopki mailowe
                dla wszystkich pracowników firmy w oparciu o motyw przewodni
                użyty na pierwszym widoku strony internetowej, pokazującym mapę
                Europy z zaznaczoną siedzibą firmy, a także globalnym zasięgiem
                jej aktywności.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid palettenwerk_section_8">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <div className="inner">
              <h3>Kampania Leadowa</h3>
              <p>
                Na potrzeby kampanii (poza jej zaprojektowaniem i realizacją)
                wykonalismy paczki banerów statycznych i dynamicznych
              </p>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <img
              src={require("../../assets/img/portfolio/palettenwerk_img_4.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-12">
            <img
              src={require("../../assets/img/portfolio/palettenwerk_img_5.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default PalettenWerk;
